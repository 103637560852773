<template>
  <!--
    The view for the ThemeElementDetail-component.
    Used to display details of one themeElement
  -->
  <span>
    <LayoutImageEdit
      v-if="themeId && elementId && $route.fullPath.includes('layoutimage')"
      :theme-id="themeId"
      :element-id="elementId"
    />
    <UserDependentImageEdit
      v-if="themeId && elementId && $route.fullPath.includes('userdependentimage')"
      :theme-id="themeId"
      :element-id="elementId"
    />
    <LayoutTextEdit
      v-if="themeId && elementId && $route.fullPath.includes('layouttext')"
      :theme-id="themeId"
      :element-id="elementId"
    />
    <UserDependentTextEdit
      v-if="themeId && elementId && $route.fullPath.includes('userdependenttext')"
      :theme-id="themeId"
      :element-id="elementId"
    />
  </span>
</template>

<script>
    export default {
      name: "ThemeElementDetail",
      components: {
        LayoutImageEdit: () => import('@/components/VideoPostprocessing/Theme/LayoutImageEdit.vue'),
        UserDependentImageEdit: () => import('@/components/VideoPostprocessing/Theme/UserDependentImageEdit.vue'),
        LayoutTextEdit: () => import('@/components/VideoPostprocessing/Theme/LayoutTextEdit.vue'),
        UserDependentTextEdit: () => import('@/components/VideoPostprocessing/Theme/UserDependentTextEdit.vue')
      },
      props: {
        themeId: {
          type: String,
          required: true
        },
        elementId: {
          type: String,
          required: true
        }
      },
      metaInfo () {
        return {
          title: this.$t('videoPostProcessing.title')
        }
      }
    }
</script>